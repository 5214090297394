<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn v-if="canViewRole" class="mx-2" @click="dialogRoles = true">
        <v-icon>mdi-account-plus-outline</v-icon> Roles
      </app-btn>
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New User
      </app-btn>
    </v-col>
    <material-card icon="mdi-account-group" icon-small color="primary" :title="selectedCompanyName + ' Users'">
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" class="ml-auto" hide-details label="Search records"
          single-line style="max-width: 250px" />

        <v-divider class="mt-3" />

        <v-data-table :headers="headers" :items="users" :search.sync="search" multi-sort :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 15],
        }">
          <template v-slot:[`item.role`]="{ item }">
            <div v-if="item.role">
              {{ item.role }}
            </div>
            <div v-else>
              --
            </div>
          </template>
          <template v-slot:top>
            <roles :show="dialogRoles" :roles="roles" @close="dialogRoles = false" />
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="UserForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.firstName" label="First Name" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.lastName" label="Last Name" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.userName" label="Username" :disabled="editedIndex > -1" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.email" label="Email" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-if="editedIndex < 0" v-model="editedItem.password" label="Password" />
                        </v-col>
                        <v-col v-if="canViewRole" cols="12">
                          <v-select v-model="editedItem.role" :items="roles" item-text="name" item-value="name"
                            label="Role" />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="canEditUser" medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>

            <v-icon v-if="canDeleteUser" medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        </editable-table>
        </data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import UserDataService from '@/services/UserDataService'
import Roles from './components/Roles.vue'
import { get } from 'vuex-pathify'

export default {
  name: 'DataTablesView',
  components: { Roles },
  data: () => ({
    permissions: localStorage.getItem('permissions'),
    rules: {
      required: (value) => !!value || 'Required.',
      length15: (value) => value.length <= 15 || 'Maximum 15 characters.',
    },
    dialog: false,
    dialogDelete: false,
    dialogRoles: false,
    headers: [],
    users: [],
    roles: [],
    editedIndex: -1,
    editedItem: {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      role: 'User',
    },
    defaultItem: {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      role: 'User',
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Add New User' : 'Edit User'
    },
    canCreateUser() {
      return this.permissions.includes('Permissions.Users.Create')
    },
    canEditUser() {
      return this.permissions.includes('Permissions.Users.Edit')
    },
    canDeleteUser() {
      return this.permissions.includes('Permissions.Users.Delete')
    },
    canViewRole() {
      return this.permissions.includes('Permissions.Roles.ViewAll')
    },
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      UserDataService.getAll(this.selectedCompanyId).then((response) => {
        this.users = response.data?.data || []
      })
      UserDataService.getAllRoles().then((response) => {
        this.roles = response.data
      })

      if (this.canEditUser || this.canDeleteUser) {
        this.headers = [
          {
            text: 'Username',
            align: 'start',
            sortable: false,
            value: 'userName',
          },
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Email', value: 'email' },
          // { text: 'Role', value: 'role' },
          { text: 'Actions', align: 'end', value: 'actions', sortable: false },
        ]
      } else {
        this.headers = [
          {
            text: 'Username',
            align: 'start',
            sortable: false,
            value: 'userName',
          },
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Email', value: 'email' },
          // { text: 'Role', value: 'role' },
        ]
      }
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      UserDataService.delete(this.editedItem.id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success('User deleted successfully.', 'Success', {
              position: 'topRight',
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        })
      this.users.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.$refs.UserForm.validate()) {
        if (this.editedIndex > -1) {
          const data = this.editedItem
          const index = this.editedIndex
          UserDataService.update(data).then((response) => {
            if (response.status === 200) {
              this.$set(this.users, index, data)
              this.$toast.success('User updated successfully.', 'Success', {
                position: 'topRight',
              })
            }
          })
        } else {
          if (this.selectedCompanyId === 0) {
            this.$toast.error('Company not found.', 'Error', {
              position: 'topRight',
            })
            return
          }

          this.editedItem.companyId = this.selectedCompanyId
          const item = this.editedItem

          this.$http.post('/Authentication/register', this.editedItem).then((response) => {
            if (response.data.success) {
              this.$toast.success('User added successfully.', 'Success', {
                position: 'topRight',
              })
              this.users.unshift(item)
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
        }
        this.close()
      }
    },
  },
}
</script>
